import { render, staticRenderFns } from "./BackNextButton.vue?vue&type=template&id=b4ced840&scoped=true"
import script from "./BackNextButton.vue?vue&type=script&lang=js"
export * from "./BackNextButton.vue?vue&type=script&lang=js"
import style0 from "./BackNextButton.vue?vue&type=style&index=0&id=b4ced840&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4ced840",
  null
  
)

export default component.exports